<template>
  <div class="orderDetails">


    <van-nav-bar :title="title" left-arrow fixed @click-left="onClickLeft"></van-nav-bar>

    <div class="trade_tips">{{ $t('app.trade_tips') }}</div>

    <div class="ticketDiv">

      <div class="ticket1">
        <img :src="ticketDetail?.productPicturePath ? ticketDetail.productPicturePath : NoPicture" />
        <div>
          <div class="title1">
            {{ ticketDetail.productName }}
          </div>
          <div class="title2">
            {{ ticketDetail.priceTypeName }} / {{ num }} {{ Number(num) > 1 ?  this.$t('app.tickets_s') : this.$t('app.tickets') }}
          </div>
          <div class="title2">
            {{ $t('app.non_refundable') }}
          </div>
        </div>


      </div>
      <div class="ticketDate">{{ ticketDetail.productUseDate }}</div>

      <div style="display: flex;justify-content: space-between;font-size:16px;">
        <div>{{ $t('app.total') }}</div>
        <div style="font-weight: 600">￥{{ priceFormat(ticketDetail.priceTypeList[0].totalAmount) }}</div>
      </div>

    </div>


    <div class="bodyDiv">
      <div class="title">{{ $t('app.liaisonDetail') }}</div>
      <!-- <div class="infoDiv">
        <div class="infoType">{{ $t('app.name') }}</div>
        <div class="infoValue">{{ name }}</div>
      </div> -->
      <div class="infoDiv">
        <div class="infoType">{{ $t('app.eMail') }}</div>
        <div class="infoValue">{{ eMail }}</div>
      </div>
    </div>

    <div class="sctai" >
      <img :src="icon" alt="img">
      <div>
        <router-link :to="{path: '/SpecificCommercialTransactionsActInformation'}">{{ $t("app.SCTAI_title")}}</router-link>
      </div>
    </div>
  <!-- 立即支付 -->
    <div class="payBtn" @click="toPay()" v-if="!isHKValue()">
      {{ $t('app.PayNow') }}
    </div>
    <div class="payBtn" @click="toPayWeChat()" v-else>
      {{ $t('app.wechatPay') }}
    </div>

    <!-- <contact-us></contact-us> -->

  </div>
</template>

<script>

import apis from "../../utils/apis";
import ContactUs from "../../components/contactUs";
import { NavBar } from 'vant';
import Vue from 'vue';
import NoPicture from "../../assets/img/no_picture.png";
import sctai_icon from "../../assets/img/ic_sctai.png";
import { hostUrl, hostUrlHK } from "../../utils/apis";

Vue.use(NavBar);

let { login } = apis
let { OrderDetail, getWechatPayValue, getOauthCode } = apis

export default {
  components: { ContactUs },

  data() {
    return {
      NoPicture: NoPicture,
      icon:sctai_icon,
      title: this.$t('app.OrderDetails'),
      name: "UNKNOWN",
      eMail: "UNKNOWN",
      payUrl: "",
      ticketDetail: {},
      num: "",
      oauthCode: '',
      WechatPayValue: {},
    };
  },

  created() {

    // console.log(this.$route.query, '----999----')
    // this.payUrl = decodeURIComponent(this.$route.query.payUrl)

    this.ticketDetail = JSON.parse(localStorage.getItem("ticketDetail"))
    this.num = this.ticketDetail.priceTypeList.length
    this.name = localStorage.getItem("namePay");
    this.eMail = localStorage.getItem("emailPay");
    // this.getOauthCodeUrl();
    
    // this.getOrderDetails()


  },

  methods: {

    // weChatPay(value) {
    //   if (typeof WeixinJSBridge == "undefined") {
    //     if (document.addEventListener) {
    //       document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady(value), false);
    //     } else if (document.attachEvent) {
    //       document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady(value));
    //       document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady(value));
    //     }
    //   } else {
    //     this.onBridgeReady(value);
    //   }
    // },

    // onBridgeReady(value) {
    //   WeixinJSBridge.invoke('getBrandWCPayRequest', {
    //     "appId": value.appId,     //公众号ID
    //     "timeStamp": value.timeStamp,     //时间戳，自1970年以来的秒数
    //     "nonceStr": value.nonceStr,      //随机串
    //     "package": value.packag,
    //     "signType": value.signType,     //微信签名方式：
    //     "paySign": value.paySign //微信签名
    //   },
    //   function(res) {
    //     if (res.err_msg == "get_brand_wcpay_request:ok") {
    //       // 支付成功

    //     } else {
    //       // 支付失败
    //     }
    //   });
    // },

    toPay() {
      //跳转到支付页面
      // location.replace(this.payUrl);
      this.payUrl = decodeURIComponent(localStorage.getItem('payUrl'));
      location.replace(this.payUrl);
      //this.$router.push({path: '/payPage', query: {src: this.payUrl}})
    },
    toPayWeChat() {
      const browser = window.navigator.userAgent.toLowerCase()
      if (browser.indexOf('micromessenger') < 0) {
        return alert(this.$t('app.browserError'));
      }
      this.oauthCode = this.$route.query.code;
      let payParams = JSON.parse(localStorage.getItem("payParams"))
      let orderId = localStorage.getItem("payValue.orderId");
      let params = {
        oauthCode: this.oauthCode,
        totalAmount: payParams.totalAmount * 100,
        productName: payParams.productName,
        outTradeNo: orderId,
        redirectUrl: hostUrlHK + "/payResult"
      }
      getWechatPayValue({...params}, response => {
        if (response.msg === '') {
          alert(this.$t('app.wechatPayError'))
        } else {
          window.location.href = response.msg
        }
      })
    },
    isHKValue() {
      const path = window.location.href;
      let flag = false
      if(path.indexOf(hostUrlHK) > -1) {
        flag = true
      }
      return flag
    },

    getOrderDetails() {
      let params = {
        "mail": "", //注册用的邮箱，接收系统发送的验证码。
        "orderId": "" //系统订单ID
      }
      OrderDetail({ ...params }, data => {
        // console.log('12345', data)
      })
    },

    onClickLeft() {
      this.$router.back()
    },
  },
};
</script>

<style scoped lang="less">
.sctai{
  a{
    color: black !important;
    text-decoration: underline;
  }
  img {
    width: 16px;
    height: 16px;
  }
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-left: 0.75rem;
  text-align: center;

}

/deep/ .van-icon {
  color: black;
  font-size: .36rem;

}

/deep/ .van-nav-bar__title {
  font-weight: 700;
  font-size: .4rem;
}

.orderDetails {
  background: #f8f8f8;
  // height: 100vh;
  padding-top: 1rem;
  font-size: 0.26rem;
  padding-bottom: .5rem;

  .trade_tips {
    background-color: #ECF3FC;
    margin: .3rem 0;
    padding: .3rem .5rem;
    text-align: left;
    font-size: 14px;
    color: #eb455f;
  }

  .ticketDiv {
    //display: flex;
    background: white;
    padding: 0.3rem 0.5rem 0.5rem 0.5rem;

    .ticket1 {
      display: flex;
      text-align: left;
      width: 100%;
      align-items: center;

      img {
        border-radius: .08rem;
        height: 1.4rem;
        width: 1.4rem;
        margin-right: 0.2rem;
      }

      .title1 {
        display: -webkit-box;
        width: 100%;
        color: #4a4a4a;

        overflow: hidden;
        -webkit-box-orient: vertical;
        white-space: normal;
        -webkit-line-clamp: 3;
        color: black;
        font-weight: 500;

        // width: 3rem;
        font-size: 0.3rem;
      }

      .title2 {
        color: #9a9a9a;
        font-size: 0.26rem;
      }
    }


    .ticketDate {
      font-size: .36rem;
      font-weight: 600;
      text-align: left;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .allIdsDiv {
    padding: 0.4rem;
    background: #FFFFFF;
    align-items: start;
    font-size: 0.26rem;
    margin-top: 0.34rem;
    text-align: left;

    .title {
      font-size: 0.36rem;
    }

    .infoDiv {
      margin-top: 0.4rem;
      height: .8rem;


    }
  }

  .infoValue {
    height: .6rem;
    line-height: .6rem;
    font-size: 16px;
  }

  .payBtn {
    margin-top: 20px;
    background: #f2b203;
    color: black;
    width: 6rem;
    height: 1rem;
    line-height: 1rem;
    border-radius: 0.2rem;
    margin-left: 0.75rem;
    // margin-bottom: 0.56rem;
    text-align: center;
    font-weight: 700;
    font-size: 0.46rem;
  }

  .bodyDiv {
    padding: 0.4rem;
    background: #FFFFFF;
    align-items: start;
    font-size: 0.26rem;
    text-align: left;
    margin-top: 0.34rem;

    .title {
      font-size: 0.36rem;
      margin-bottom: .2rem;
    }

    .infoDiv {
      display: flex;
      padding: 0.1rem 0;

      .infoType {
        font-size: .32rem;
        height: .6rem;
        line-height: .6rem;
        width: 30%;
      }


    }
  }


}
</style>
