<template>
  <div class="contactUsDiv">

    <img :src="logo" class="logo"/>

    <div class="contactText">
      {{ $t('app.howToContact') }}
    </div>

    <div class="numberDiv">
      <img :src="phoneIcon" class="eMail"/>
      <div class="number">{{ $t('app.tel') }}:15100000000</div>
    </div>
    <div class="numberDiv">
      <img :src="eMailIcon" class="eMail"/>
      <div class="number">{{ $t('app.mail') }}:mb@gmail.com</div>
    </div>

  </div>
</template>

<script>
import logo_black from "../assets/img/logo_black.png";
import e_mail from "../assets/order/email.png"
import phone from "../assets/order/phone.png"

export default {
  name: "contactUs",
  data() {
    return {
      logo: logo_black,
      eMailIcon: e_mail,
      phoneIcon: phone,

    }
  },


}
</script>

<style scoped lang="less">
.contactUsDiv {
  background-color: #faf1ed;
  padding: 0.5rem;
  text-align: left;

  .logo {
    width: 2.1rem;
    height: 0.6rem;
  }

  .contactText {
    margin-top: 0.2rem;
    font-size: 0.2rem;
  }

  .numberDiv {
    display: flex;
    align-items: center;
    margin-top: 0.2rem;
    font-size: 0.26rem;

    .eMail {
      width: 0.31rem;
      height: 0.31rem;
    }

    .number {
      margin-left: 0.2rem;
    }
  }
}

</style>